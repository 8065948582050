<template>
    <div>
        <vx-card title="Edit User Role" title-color="black" noShadow noRound>
            <vs-row>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <vs-input
                        v-model="user.first_name"
                        label="First Name"
                        class="w-full"
                        :disabled="user.id"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.first_name">
                        {{ api_errors.first_name.join(', ') }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <vs-input
                        v-model="user.last_name"
                        label="Last Name"
                        class="w-full"
                        :disabled="user.id"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.last_name">
                        {{ api_errors.last_name.join(', ') }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <vs-input
                        v-model="user.email"
                        label="Email"
                        class="w-full"
                        type="Email"
                        :disabled="user.id"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.email">
                        {{ api_errors.email.join(', ') }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <vs-input
                        v-model="user.password"
                        label="Password"
                        class="w-full"
                        type="Password"
                        :disabled="user.id"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.password">
                        {{ api_errors.password.join(', ') }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <InputSelect 
                        :model.sync="user.role" 
                        input_class="w-full bg-white" 
                        label="Role"
                        :options="role_options"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.role">
                        {{ api_errors.role }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="12" vs-lg="12" class="mb-3">
                    <label class="vs-input--label block">Is Active</label>
                    <div class="flex mt-3 align-center">
                        <vs-radio :disabled="user.id" v-model="user.is_active" vs-name="is_active" :vs-value="true">Yes</vs-radio>
                        <vs-radio :disabled="user.id" v-model="user.is_active" vs-name="is_active" :vs-value="false" class="mx-3">No</vs-radio>
                    </div>
                    <small class="block text-danger mt-1" v-if="api_errors.is_active">
                        {{ api_errors.is_active.join(', ') }}
                    </small>
                </vs-col>
            </vs-row>

            <vs-row class="mt-base">
                <vs-col class="text-center">
                    <vs-button @click="save" :disabled="submit_disabled">
                        Save
                    </vs-button>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>

import { Request } from '../../utils/requests';

export default {
    data() {
        return {
            user: {
                first_name: null,
                last_name: null,
                role: null,
                email: null,
                password: null,
                is_active: true
            },

            submit_disabled: false,
            api_errors: {},

            role_options: [
                {label: 'Admin', value: 'a'},
                {label: 'Staff', value: 's'},
                {label: 'Client', value: 'c'},
            ]
        }
    },
    watch: {
        "$route.params.user_id"(newV, oldV) {
            if (newV == oldV)
                return;
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.user_id = this.$route.params.user_id
            if (this.user_id) this.load_data()
        },
        load_data() {
            let request_method = 'GET', url = this.$store.state.apiendpoints.organisation_user + this.user_id + "/"
            
            this.$vs.loading()
            
            Request(this, request_method, url).then(
                res => {
                    this.$vs.loading.close()
                    this.user = res
                },
                err => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        time: 3000,
                        title: 'Error',
                        text: "An unknown error occurred! Please try again!",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        save() {
            let request_method = 'POST', url = this.$store.state.apiendpoints.organisation_user
            if (this.user_id) {
                request_method = 'PUT'
                url += this.user_id + '/'
            }

            let payload = {
                ...this.user
            }

            this.submit_disabled = true

            Request(this, request_method, url, null, payload).then(
                res => {
                    this.user = res
                    this.submit_disabled = false

                    this.$vs.notify({
                        time: 3000,
                        title: 'Saved',
                        text: "Saved Successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                    this.$router.push({name: 'user-list'})
                },
                err => {
                    this.submit_disabled = false
                    if (err.error) {
                        this.api_errors = err.error
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: "Please fix the errors and try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: "An unknown error occurred! Please try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })

                    }
                }
            )
        }
    }
}
</script>